<template>
  <div class="card card-animation-on-hover text-left" style="margin: 4px;padding: 4px;
  background-color: rgb(45,44,44);opacity: 0.9;"
  >
    <router-link :to="event.metadata.magicEvent ? { path: this.event.metadata.link } : {name:  'Event', params:  {slug: this.event.slug}}">
      <img
        v-lazy=" this.event.metadata.main_image.imgix_url + `?q=&w=${imageSize}&h=${imageSize}&fit=clamp`"
        style="float:left;margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0);border-radius: 0"
        v-bind:alt="this.event.title"
        :height="imageSize"
        :width="imageSize"
      />
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; line-height: 0.7rem"
        v-if="!this.past">
        <span style="font-weight: 700 !important;" v-if="!withoutBrand">{{ this.event.title.split("@")[0] }}</span>
        <span style="font-weight: 700 !important;" v-if="withoutBrand">{{ this.event.title.split("@")[0].split('|')[0] }}</span>

        <p>

          {{ getDay(this.event.metadata.date + 'T' + this.event.metadata.start_time) }},
          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }} ●

          {{
            event.metadata.start_time
          }} Hrs

        </p>
        <small v-if="this.withVenue" style="margin-top: -50px;color: silver;text-transform: uppercase">{{ venue }} </small>
        <small style="color: silver;text-transform: uppercase" v-if="this.withBrand" >  {{ brand }} </small>


      </div>
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; " v-if="this.past">
        <span style="font-weight: 700 !important;">{{ this.event.title.split("@")[0] }}</span>
        <br>
        <p>
          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }},
          {{ getYear(event.metadata.date + 'T' + event.metadata.start_time) }}
        </p>
      </div>
      <small style="font-size: 0.6rem;background-color: #0e0e0e;bottom: 0px; right: 0;position: absolute" class="pull-right" v-if="!past">
        <i class="tim-icons icon-single-copy-04 " style="padding: 5px"></i>
        <i class="tim-icons icon-chat-33" style="padding: 5px"></i>
        <i class="tim-icons icon-cart" style="padding: 5px"  v-if="!event.metadata.magicEvent"></i>
      </small>

    </router-link>
  </div>
</template>
<script>
import moment from 'moment';
import {venueDecoder, brandDecoder} from "@/common/Decoder";
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'MiniEventCard',
  mixins: [eventDateUtils],
  props: ['event', 'past', 'withVenue', 'withoutBrand', 'large', 'withBrand','idx'],
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd, MMMM Do YYYY';
      const formatOptions = {
        sameDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()}] `
            : `[Today ~] ${format}`;
        },
        nextDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()} / ] ${format}`
            : `[Tomorrow ~] ${format}`;
        },
        nextWeek: format,
        sameElse: format,
      };

      return moment(partyDate).calendar(null, formatOptions);
    },
  },
  data: () => {
    return {};
  },
  computed: {
    imageSize: {
      get: function () {
        return  this.large ? '100' : '70';
      },
    },
    venue: {
      get: function () {
        return venueDecoder(this.event.title)?.title || 'tulum';
      },
    },
    brand: {
      get: function () {
        return brandDecoder(this.event.title)?.title || '';
      },
    }
  },
};
</script>

